<script lang="ts" setup>
import type { CoinWithAmount } from '@base/types'

const props = defineProps<{
  modelValue: CoinWithAmount
  showOnly?: 'fiat' | 'tokens'
  isStandalone?: boolean // If the dropdown is NOT a part of the input
}>()

const emit = defineEmits(['update:modelValue'])

const selectedCoin = computed<CoinWithAmount>({
  get () {
    return props.modelValue
  },
  set (value) {
    emit('update:modelValue', value)
  },
})

const { coins, tokens, fiatCoins } = storeToRefs(useCoins())

const showCoins = computed(() => {
  if (!props.showOnly) return coins.value
  else if (props.showOnly === 'fiat') return fiatCoins.value
  else return tokens.value.filter(token => token.chainCharges.length > 0)
})
</script>

<template>
  <div h-full>
    <HeadlessListbox v-model="selectedCoin" as="span">
      <HeadlessListboxButton
        :class="[
          'flex justify-center items-center gap-12px h-full w-full focus:fubex-focus-ring duration-200',
          !isStandalone
            ? 'bg-neutral-900 hover:bg-neutral-600 focus:bg-neutral-600 fubex-rounded-lg rounded-l-0 dark:bg-neutral-50 dark:hover:bg-neutral-100 dark:focus:bg-neutral-100 dark:text-neutral-900 border-1 border-l-0'
            : 'bg-white fubex-rounded-lg dark:bg-neutral-200 dark:hover:bg-neutral-100 dark:focus:bg-neutral-100 border-1 min-h-58px',
        ]"
        flex justify-between px-16px
      >
        <div flex gap-8px>
          <img
            v-if="selectedCoin.code"
            :src="`/img/coins/${selectedCoin.code}.svg`"
            :alt="selectedCoin.name"
            class="w-24px"
          >
          {{ selectedCoin.code?.toUpperCase() }}
        </div>
        <UnoIcon i-fubex-chevron-down class="h-16px w-16px" />
      </HeadlessListboxButton>
      <HeadlessListboxOptions
        class="w-full bg-shades-0 z-24 text-neutral-900 mt-8px fubex-rounded-lg fubex-shadow-lg py-16px absolute outline-neutral-900 dark:bg-neutral-50"
        :class="{ 'h-210px overflow-y-scroll': showCoins.length >= 5 }"
      >
        <HeadlessListboxOption
          v-for="coin in showCoins"
          :key="coin.code"
          v-slot="{ active }"
          :value="{ ...coin, amount: modelValue.amount }"
        >
          <div
            class="flex justify-stretch items-center gap-12px pl-32px py-8px h-full w-full cursor-pointer hover:bg-neutral-100"
            :class="{ 'bg-neutral-100': active }"
          >
            <img :src="`/img/coins/${coin.code}.svg`" :alt="coin.name" class="w-18px">
            {{ coin.code.toUpperCase() }}
          </div>
        </HeadlessListboxOption>
      </HeadlessListboxOptions>
    </HeadlessListbox>
  </div>
</template>
