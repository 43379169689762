import type { Coin, CoinWithAmount, SwapType, CryptoNetwork, SwapForm, PlatformPublic, ChargeDeep, SwapCalculationInput, SwapCalculationOutput } from '@base/types'
import { useToast } from 'vue-toastification'
import type { Prisma } from '@develit-io/fubex-exchange-prisma'
import * as yup from 'yup'
import { useExchangeOfficeEmployee } from '../../../modules/fubex-profile/composables/useExchangeOfficeEmployee' // TODO: fix cross layer imports

export const useSwapStore = defineStore('swap', () => {
  const toast = useToast()

  const { platform } = useAppConfig()
  const { $t, $ts } = useI18n()
  const { user, limitRemaining, isLogged, verifiedLevelNum, isExchangeOfficeEmployee } = storeToRefs(useUser())
  const { cryptoAddressValidation, emailValidation } = useValidations()
  const { selectedExchangeOfficeBranch } = storeToRefs(useExchangeOfficeEmployee())

  const validationSchema = yup.object({
    swap: yup.object().shape({
      swapFrom: yup.object().shape({
        amount: yup.number().required(String($t('components.swapForm.amountRequired'))),
        price: yup.number().required(),
      }).test({
        name: 'verified-used',
        test: () => {
          if (isLogged.value)
            return verifiedLevelNum.value > 0
          return true
        },
        message: String($t('components.swapForm.verifiedAccountRequired')),
      }).test({
        name: 'personal-limit',
        test: val => {
          if (isLogged.value)
            return val.price * val.amount <= limitRemaining.value
          return true
        },
        message: String($t('components.swapForm.exceedsPersonalLimit', { n: `${formatPriceInEur(limitRemaining.value)}` })),
      })
        .test({
          name: 'minimum-eur',
          test: val => val.amount * val.price >= 10,
          message: $ts('components.swapForm.minimumEur'),
        })
        .test({
          name: 'maximum-eur',
          test: val => val.amount * val.price <= 100000,
          message: $ts('components.swapForm.maximumEur'),
        }),
      swapTo: yup.object().shape({
        amount: yup.number()
          .required(String($t('components.swapForm.toAmountRequired')))
          .moreThan(0, String($t('components.swapForm.toAmountGreaterThanZero'))),
      }),
    }),
    swapType: yup.string().required().oneOf<SwapType>(['ONETIME', 'RECURRING']),
    fromType: yup.string().required().when(
      'swap.swapFrom.type',
      {
        is: 'FIAT',
        then: () => yup.string().oneOf(['bank'], $ts('components.swapForm.paymentMethodNotSelected')),
        otherwise: () => yup.string().oneOf(['wallet'], $ts('components.swapForm.paymentMethodNotSelected')),
      },
    ),
    fromBankAccount: yup.object().when('fromType', {
      is: 'bank',
      then: schema => schema.required($ts('components.swapForm.bankAccountNotSelected')),
    }),
    fromNetwork: yup.object().when('fromType', {
      is: 'wallet',
      then: schema => schema.required(String('')),
    }),
    toType: yup.string().required().when(
      'swap.swapTo.type',
      {
        is: 'FIAT',
        then: () => yup.string().oneOf(['bank'], String('')),
        otherwise: () => yup.string().oneOf(['wallet'], String('')),
      },
    ),
    toBankAccount: yup.object().when('toType', {
      is: 'bank',
      then: schema => schema.required(String('')),
    }),
    toWalletAddress: yup.string().when('toType', {
      is: 'wallet',
      then: () => yup.string().required($ts('components.swapForm.enterWalletAddress')).test({
        name: 'to-address-regex',
        test: val => {
          return validateToWalletAddress(val)
        },
        message: $ts('components.swapForm.invalidWalletAddress'),
      }),
    }),
    toNetwork: yup.object().when('toType', {
      is: 'wallet',
      then: schema => schema.required($ts('components.swapForm.networkNotSelected')).test({
        name: 'min-amount',
        test: val => {
          return validateMinOrder(val as CryptoNetwork)
        },
        message: $ts('components.swapForm.amountBelowMinimum'),
      }),
    }),
    consent: yup.boolean().required().oneOf([true], String('')),
  })

  const validationSchemaForExchangeOfficeEmployee = yup.object().shape({
    swap: yup.object().shape({
      swapFrom: yup.object().shape({
        amount: yup.number().required('Enter the amount.'),
        price: yup.number().required(),
      })
        .test({
          name: 'verified-used',
          test: () => {
            if (isLogged.value)
              return verifiedLevelNum.value > 0
            return true
          },
          message: 'You have no Verification Level assigned.',
        })
        .test({
          name: 'personal-limit',
          test: val => {
            if (isLogged.value && !isNaN(val.price) && !isNaN(val.amount))
              return val.price * val.amount <= limitRemaining.value
            return true
          },
          message: `You are exceeding your limit (remaining ${formatPriceInEur(limitRemaining.value)}).`,
        })
        .test({
          name: 'minimum-eur',
          test: val => val.amount * val.price >= 10,
          message: 'Minimal exchange amount is 10 EUR',
        })
        .test({
          name: 'maximum-eur',
          test: val => val.amount * val.price <= 100000,
          message: 'Maximum exchange amount 100 000 EUR',
        }),
      swapTo: yup.object().shape({
        amount: yup.number()
          .required('To - enter the amount.')
          .moreThan(0, 'To - has to be bigger than 0'),
      }),
    }),
    paymentSource: yup.string()
      .required('Select a payment method.')
      .oneOf(['CASH', 'CARD'], 'Invalid payment method.'),
    clientEmail: emailValidation,
    cashReceived: yup.string().test('cash-received', `${$ts('pages.exchange.wrongAmount')}`, function (value) {
      const { paymentSource, swap } = this.parent
      if (paymentSource === 'CASH') {
        return value !== undefined && +value >= swap.swapFrom.amount
      }
      return true
    }),
    consent: yup.boolean()
      .test('consent-required', `${$ts('pages.exchange.signConsentRequired')}`, function (value) {
        const { paymentSource } = this.parent
        if (paymentSource === 'CARD') {
          return value === true
        }
        return true
      }),
    offerSigned: yup.boolean().required('Signing the offer is required').oneOf([true], 'The client has to sign the offer'),
    idCardChecked: yup.boolean().required('Verifying the document data is requested').oneOf([true], 'Check the data from the client`s document'),
    idCardRequested: yup.boolean().required('Retrieving the identity document is requested').oneOf([true], 'Ask the client for the identity document'),
    clientIdNumber: yup.string().when('idCardRequested', (values, schema) => {
      const [idCardRequested] = values as [boolean]
      const [idCardChecked] = values as [boolean]
      return idCardRequested === true && !idCardChecked
        ? schema.required('Client ID Number is required')
        : schema.notRequired()
    }),
    clientCountry: yup.string().when('idCardRequested', (values, schema) => {
      const [idCardRequested] = values as [boolean]
      const [idCardChecked] = values as [boolean]
      return idCardRequested === true && !idCardChecked
        ? schema.required('Client country is required')
        : schema.notRequired()
    }),
  })

  const { values: formValues, isSubmitting, errors, defineField, setFieldValue, resetField, resetForm, handleSubmit, meta: formMeta, isFieldValid } = useForm<SwapForm>({
    validationSchema: isExchangeOfficeEmployee.value ? validationSchemaForExchangeOfficeEmployee : validationSchema,

    initialValues: {
      swap: {
        calculationDirection: 'from',
      },
      swapType: 'ONETIME',
      fromType: isExchangeOfficeEmployee ? 'cash' : 'bank',
      toType: 'wallet',
      consent: false,
      clientEmail: '',
    },
  })

  const [swapTypeValue, swapTypeBinds] = defineField('swapType', { props: VProps })
  const [fromTypeValue, fromTypeBinds] = defineField('fromType', { props: VProps })
  const [fromBankAccountValue, fromBankAccountBinds] = defineField('fromBankAccount', { props: VProps })
  const [fromNetworkValue, fromNetworkBinds] = defineField('fromNetwork', { props: VProps })
  const [toTypeValue, toTypeBinds] = defineField('toType', { props: VProps })
  const [toBankAccountValue, toBankAccountBinds] = defineField('toBankAccount', { props: VProps })
  const [toWalletAddressValue, toWalletAddressBinds] = defineField('toWalletAddress', { props: VProps })
  const [toNetworkValue, toNetworkBinds] = defineField('toNetwork', { props: VProps })
  const [consentValue, consentBinds] = defineField('consent', { props: VProps })
  const [offerSignedValue, offerSignedBinds] = defineField('offerSigned', { props: VProps })
  const [idCardRequestedValue, idCardRequestedBinds] = defineField('idCardRequested', { props: VProps })
  const [idCardCheckedValue, idCardCheckedBinds] = defineField('idCardChecked', { props: VProps })
  const [widgetSwapValue, widgetSwapBinds] = defineField('swap', { props: VProps })
  const [clientEmailValue, clientEmailBinds] = defineField('clientEmail', { props: VProps })
  const [clientIdNumberValue, clientIdNumberBinds] = defineField('clientIdNumber', { props: VProps })
  const [clientCountryValue, clientCountryBinds] = defineField('clientCountry', { props: VProps })
  const [paymentSourceValue, paymentSourceBinds] = defineField('paymentSource', { props: VProps })
  const [cashReceivedValue, cashReceivedBinds] = defineField('cashReceived', { props: VProps })

  const { tokens } = storeToRefs(useCoins())
  const { getReactiveCoinByCode } = useCoins()
  const { fetchDepositChains } = useCodes()
  const { depositChains } = storeToRefs(useCodes())

  const swapFromValue = ref<CoinWithAmount>()
  const swapToValue = ref<CoinWithAmount>()
  const profitBitbeliPercent = ref<number>()
  const profitPlatformPercent = ref<number>()
  const platformCharges = ref<ChargeDeep[]>()

  const output = ref<SwapCalculationOutput>()

  const initSwapNetworkValues = () => {
    setFieldValue('toNetwork', cryptoNetworks.value[0])
    setFieldValue('fromNetwork', cryptoNetworks.value[0])
  }

  const init = async () => {
    await until(tokens).toMatch(c => c.length > 0, { timeout: 10000 })

    try {
      const platformResult = await $fetch('/api/platform')
      const { profitBitbeliPercent: parsedProfitBitbeliPercent } = superjsonParse<PlatformPublic>(platformResult)

      profitBitbeliPercent.value = parsedProfitBitbeliPercent
    }
    catch (e: any) {
      console.error(e)
      toast.error(e.statusMessage || 'Unable to fetch platform data')
    }

    try {
      const chargesResult = await $fetch('/api/platform/charges')
      const charges = superjsonParse<ChargeDeep[]>(chargesResult)

      platformCharges.value = charges
    }
    catch (e: any) {
      console.error(e)
      toast.error('Unable to fetch charges')
    }

    await fetchDepositChains()
    swapFromValue.value = { ...getReactiveCoinByCode('CZK').value! }
    swapToValue.value = { ...getReactiveCoinByCode('BTC').value! }

    // Pokud setnu amount, tak se fetchuje conversio rate i bez inputu, ale zase
    // jsou validations automaticky v erroru.
    // swapFromValue.value = { ...getReactiveCoinByCode('CZK').value!, amount: 0 }
    // swapToValue.value = { ...getReactiveCoinByCode('BTC').value!, amount: 0 }

    resetForm({
      values: {
        ...formValues,
        swap: {
          swapFrom: swapFromValue.value,
          swapTo: swapToValue.value,
          calculationDirection: 'from',
        },
      },
    })

    watchEffect(() => {
      if (formValues.swap.swapFrom?.amount !== undefined) {
        swapFromValue.value = { ...getReactiveCoinByCode(formValues.swap.swapFrom.code).value!, amount: formValues.swap.swapFrom.amount }
        setFieldValue('swap.swapFrom', swapFromValue.value)
        if (formValues.swap.swapFrom?.amount > 0) calculateSwapAmounts()
      }

      if (formValues.swap.swapTo?.amount !== undefined) {
        swapToValue.value = { ...getReactiveCoinByCode(formValues.swap.swapTo.code).value!, amount: formValues.swap.swapTo.amount }
        setFieldValue('swap.swapTo', swapToValue.value)
        if (formValues.swap.swapTo?.amount > 0) calculateSwapAmounts()
      }
    })
  }

  useAsyncData(() => init())

  const cryptoNetworks = computed(() => {
    if (formValues.swap.swapFrom?.type === 'CRYPTO') {
      return formValues.swap.swapFrom.chainCharges
        .filter(c => depositChains.value.includes(`${c.currencyCode}-${c.chainId}`))
        .map<CryptoNetwork>(c => ({
          id: c.chainId.toString(),
          label: c.chain.chainId,
          code: c.chain.chainId,
          charge: c.charge,
        })) ?? []
    }

    if (formValues.swap.swapTo?.type === 'CRYPTO') {
      return formValues.swap.swapTo.chainCharges
        .filter(c => depositChains.value.includes(`${c.currencyCode}-${c.chainId}`))
        .map<CryptoNetwork>(c => ({
          id: c.chainId.toString(),
          label: c.chain.chainId,
          code: c.chain.chainId,
          charge: c.charge,
        })) ?? []
    }

    return []
  })

  const swapFrom = computed(() => formValues.swap.swapFrom)
  const swapTo = computed(() => formValues.swap.swapTo)
  const fromNetwork = computed(() => formValues.fromNetwork)
  const toNetwork = computed(() => formValues.toNetwork)

  const conversionCharge = computed(() => {
    let matchingCharge = swapFrom.value?.charges.find(charge => Math.floor(swapFrom.value?.amount || 0) >= charge.amountFrom && Math.floor(swapFrom.value?.amount || 0) <= charge.amountTo)
    if (!matchingCharge) { // swapTo has charges
      matchingCharge = swapTo.value?.charges.find(charge => Math.floor(swapTo.value?.amount || 0) >= charge.amountFrom && Math.floor(swapTo.value?.amount || 0) <= charge.amountTo)
    }

    if (matchingCharge) {
      return roundMath((100 - matchingCharge.profitPlatformPercent - matchingCharge.platform.profitBitbeliPercent) / 100, 4)
    }
    return platform.defaultCharge ?? 0
  })

  // Implement calculateSwap into charges later
  // watch(
  //   () => [swapFrom.value?.price, swapTo.value?.price],
  //   () => {
  //     console.log('price changeed')
  //     calculateSwapAmounts()
  //   },
  // )
  // // const conversionCharge = computed(() => {
  //   if (!profitBitbeliPercent.value && !profitPlatformPercent.value) {
  //     return platform.defaultCharge ?? 0
  //   }
  //   else {
  //     return output.value?.chargePercent
  //   }
  // })

  const conversionRate = computed(() => {
    if (!swapFrom.value || !swapTo.value)
      return
    return swapFrom.value.price * conversionCharge.value / swapTo.value.price
  })

  // const conversionRate = computed(() => {
  //   if (!output.value)
  //     return
  //   return output.value.conversionRate
  // })

  const conversionRateString = computed(() => {
    if (!swapFrom.value || !swapTo.value || !conversionRate)
      return

    if (swapFrom.value.type === 'FIAT') {
      return `1 ${swapTo.value.code} =  ${formatPriceByCurrency(1 / conversionRate.value!, swapFrom.value.code)}`
    }
    else
      return `1 ${swapFrom.value.code} =  ${formatPriceByCurrency(conversionRate.value!, swapTo.value.code)}`
  })

  const calculateSwapAmounts = () => {
    const fiatCurrency = swapFrom.value?.type === 'FIAT' ? swapFrom.value.code : swapTo.value?.code
    profitPlatformPercent.value = platformCharges.value?.filter(charge => charge.currencyCode === fiatCurrency).find(charge => {
      let swapAmountFiat
      swapAmountFiat = formValues.swap.calculationDirection === 'from'
        ? swapFrom.value?.amount
        : swapTo.value?.amount
      if (!swapAmountFiat || typeof swapAmountFiat !== 'number')
        swapAmountFiat = 0
      return swapAmountFiat >= charge.amountFrom && swapAmountFiat <= charge.amountTo
    })?.profitPlatformPercent

    if (!profitPlatformPercent.value) {
      toast.error('Unable to calculate platform profit')
      setFieldValue('swap.swapFrom.amount', 0)
      return
    }

    if (!profitBitbeliPercent.value) {
      toast.error('Unable to calculate bitbeli profit')
      setFieldValue('swap.swapFrom.amount', 0)
      return
    }

    if (!swapFromValue.value || !swapToValue.value) {
      toast.error('Unable to calculate swap amounts')
      return
    }

    const input: SwapCalculationInput = {
      calculationDirection: formValues.swap.calculationDirection,
      exchangeDirection: swapFrom.value?.type === 'FIAT' ? 'FIAT_TO_CRYPTO' : 'CRYPTO_TO_FIAT',
      fromAmount: swapFrom.value?.amount ? roundMath(swapFrom.value?.amount, swapFrom.value?.type === 'FIAT' ? 2 : 8) : undefined,
      toAmount: swapTo.value?.amount,
      fromCurrency: { ...swapFromValue.value, priceEur: swapFromValue.value?.price ?? null },
      fromChainId: fromNetwork.value?.id ? formValues.fromNetwork!.id : undefined,
      toCurrency: { ...swapToValue.value, priceEur: swapToValue.value?.price ?? null },
      toChainId: toNetwork.value?.id ? formValues.toNetwork!.id : undefined,
      makerFeePercent: platform.makerFee * 100,
      profitBitbeliPercent: profitBitbeliPercent.value,
      profitPlatformPercent: profitPlatformPercent.value,
    }

    try {
      output.value = calculateSwap(input)
      // todo: check output value if is not null
    }
    catch (e: any) {
      console.error(e)
      return toast.error('Unable to calculate')
    }
    if (formValues.swap.calculationDirection === 'from') {
      setFieldValue('swap.swapTo.amount', Math.max(output.value!.toAmount, 0))
    }
    else {
      setFieldValue('swap.swapFrom.amount', Math.max(output.value!.fromAmount, 0))
    }
  }

  const calculateConversion = (amount: number, from: Coin, to: Coin, conversionCharge: number) => {
    if (from.type === 'FIAT')
      return amount * from.price / conversionCharge / to.price
    else
      return amount * from.price * conversionCharge / to.price
  }

  const chargeInFiat = computed(() => {
    const { amount: _amountFrom, ...from }: CoinWithAmount = swapFrom.value!
    const { amount: _amountTo, ...to }: CoinWithAmount = swapTo.value!
    if (toNetwork.value?.charge !== undefined)
      return calculateConversion(toNetwork.value?.charge, to, from, 1)
  })

  const setCryptoToken = (coin: Coin) => {
    if (swapFrom.value?.type === 'FIAT')
      setFieldValue('swap.swapTo', { ...coin, amount: swapTo.value?.amount })
    else
      setFieldValue('swap.swapFrom', { ...coin, amount: swapFrom.value?.amount })
  }

  // set default values when swap changes from fiat2crypto to crypto2fiat and vice versa
  watch(swapFrom, () => {
    if (swapFrom.value?.type === 'CRYPTO') {
      setFieldValue('fromType', 'wallet')
      setFieldValue('toType', 'bank')
    }
    else {
      setFieldValue('fromType', 'bank')
      setFieldValue('toType', 'wallet')
    }
  })

  const swapFromCurrencyCode = computed(() => swapFrom.value?.code)
  watch(swapFromCurrencyCode, () => {
    resetField('fromNetwork')
    setFieldValue('fromNetwork', cryptoNetworks.value[0])
  }, { deep: false })

  const swapToCurrencyCode = computed(() => swapTo.value?.code)
  watch(swapToCurrencyCode, () => {
    resetField('toNetwork')
    setFieldValue('toNetwork', cryptoNetworks.value[0])
  }, { deep: false })

  const orderInput = computed<Prisma.OrderCreateArgs>(() => {
    if (!output.value)
      throw new Error('Output value is undefined')

    return {
      data: {
        orderHash: 'will be generated',
        type: formValues.swapType === 'ONETIME' ? 'ONETIME' : 'RECURRING',
        fromCurrencyCode: output.value.fromCurrencyCode,
        fromAmount: output.value.fromAmount,
        fromBankAccountId: formValues.fromBankAccount?.id,
        fromChainId: output.value.fromChainId,
        toCurrencyCode: output.value.toCurrencyCode,
        toAmount: output.value.toAmount,
        toBankAccountId: formValues.toBankAccount?.id,
        toWalletAddress: formValues.toWalletAddress,
        toChainId: output.value.toChainId,
        userId: user.value!.userId,
        email: user.value?.email,
        phoneNumber: user.value?.phoneNumber,
        charge: 100 - output.value.chargePercent,
        chargeAmount: output.value.chargeAmountEur,
        fiatAmountEur: output.value.fiatAmountEur,
        chainCharge: output.value.chainCharge,
        exchangeDirection: output.value.exchangeDirection,
        paymentSource: formValues.paymentSource,
        toAmountAfterCharge: output.value.toAmountAfterCharge,
        chargeCurrency: 'EUR',
        conversionRate: output.value.conversionRate,
        exchangeOfficeBranchId: selectedExchangeOfficeBranch.value?.id,
      },
    }
  })

  function validateMinOrder (val: CryptoNetwork): boolean {
    const minimumOrder = swapTo.value?.chainCharges.find(c => c.chainId === val.id)?.minimumOrder
    if (swapTo.value?.amount && swapTo.value.amount > 0 && minimumOrder)
      return swapTo.value.amount - minimumOrder > 0
    return true
  }

  function validateToWalletAddress (address: string): boolean {
    if (toNetwork.value?.code)
      return cryptoAddressValidation(address, toNetwork.value.code)

    return false
  }

  function initExchange (coin: Coin) {
    const { $switchRoute } = useI18n()
    setCryptoToken(coin)
    $switchRoute('/profile/exchange')
  }

  return {
    formMeta,
    errors,
    handleSubmit,
    isSubmitting,
    cryptoNetworks,
    fromNetwork,
    toNetwork,
    swapFrom,
    swapTo,
    calculateConversion,
    calculateSwapAmounts,
    chargeInFiat,
    conversionRateString,
    orderInput,
    conversionCharge,
    setCryptoToken,
    resetForm,
    resetField,
    setFieldValue,
    isFieldValid,
    swapTypeValue,
    fromTypeValue,
    fromBankAccountValue,
    fromNetworkValue,
    toTypeValue,
    toBankAccountValue,
    toWalletAddressValue,
    toNetworkValue,
    consentValue,
    widgetSwapValue,
    swapTypeBinds,
    fromTypeBinds,
    fromBankAccountBinds,
    fromNetworkBinds,
    toTypeBinds,
    toBankAccountBinds,
    toWalletAddressBinds,
    toNetworkBinds,
    consentBinds,
    widgetSwapBinds,
    defineField,
    initExchange,
    initSwapNetworkValues,
    clientEmailValue,
    clientEmailBinds,
    clientIdNumberBinds,
    clientIdNumberValue,
    paymentSourceValue,
    paymentSourceBinds,
    cashReceivedValue,
    cashReceivedBinds,
    offerSignedValue,
    offerSignedBinds,
    idCardRequestedBinds,
    idCardRequestedValue,
    idCardCheckedBinds,
    idCardCheckedValue,
    clientCountryBinds,
    clientCountryValue,
    output,
  }
})
