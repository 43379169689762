import { useToast } from 'vue-toastification'
import type { VerificationType } from '@develit-io/fubex-exchange-prisma'
import type { ExchangeOfficeEmployeeBranch, ExchangeOfficeCashDesk, ExchangeOfficeClient, ExchangeOfficeClientDTO, ClientVerificationResult, SumClientResponse } from '@profile/types'

export const useExchangeOfficeEmployee = defineStore('exchangeOfficeEmployee', () => {
  const toast = useToast()
  const { $t } = useI18n()
  const selectedExchangeOfficeBranch = useState<ExchangeOfficeEmployeeBranch | undefined>('exchange-office-employee-selected-branch', () => undefined)
  const selectedExchangeOfficeCashDesk = useState<ExchangeOfficeCashDesk | undefined>('exchange-office-employee-selected-cash-desk', () => undefined)
  const exchangeOfficeEmployeeBranches = useState<ExchangeOfficeEmployeeBranch[] | undefined>('exchange-office-employee-branches', () => undefined)
  const isExchangeOfficEmployeeeBranchesFetched = useState('exchange-office-employee-branches-isFetched', () => false)
  const isKrakenBalanceEnough = useState<boolean | undefined>('exchange-office-employee-is-kraken-balance-enough', () => undefined)
  const exchangeOfficeClients = useState<ExchangeOfficeClient[] | undefined>('exchange-office-clients', () => undefined)
  const isExchangeOfficeClientsFetched = useState('exchange-office-clients-isFetched', () => false)
  const clientOrderSum = useState<number | undefined | null>('exchange-office-client-sum', () => undefined)
  const clientOrderSumPersonalData = useState<Omit<Exclude<SumClientResponse, null>, 'amount'> | undefined>('exchange-office-client-sum-is-verified', () => undefined)
  const isClientOrderSumFetched = useState('exchange-office-clients-sum-isFetched', () => false)
  const isClientOrderSumFetching = useState('exchange-office-clients-sum-isFetching', () => false)
  const isCurrentSessionEmailVerified = useState<boolean>('exchange-office-employee-client-email-verified', () => false)
  const isSendEmailVerificationFetched = useState('exchange-office-employee-client-email-verification-isFetched', () => true)
  const currentExchangeOfficeClientVerificationStatus = useState<ClientVerificationResult | undefined>('exchange-office-employee-client-verification-status', () => undefined)
  const isExchangeOfficeClientVerificationStarting = useState<boolean>('exchange-office-employee-client-verification-starting', () => false)

  const { user } = storeToRefs(useUser())

  const { isKrakenBalanceFetched, krakenBalance } = storeToRefs(useAdmin())
  const { fetchKrakenBalance } = useAdmin()

  /* onAuthStateChange was not working properly so watch user is used instead */
  // const client = useSupabaseClient()
  // client.auth.onAuthStateChange(async event => {
  //   if (event === 'SIGNED_OUT') {
  //     selectedExchangeOfficeBranch.value = undefined
  //     // selectedExchangeOfficeCashDesk.value = undefined
  //   }
  // })

  watch(user, () => {
    if (!user.value) {
      selectedExchangeOfficeBranch.value = undefined
      localStorage.setItem('selected-exchange-office-branch-id', '')
    }
  })

  const fetchExchangeOfficeEmployeeBranches = async () => {
    isExchangeOfficEmployeeeBranchesFetched.value = false
    try {
      const result = await $fetch('/api/exchange-office-employee/branch/all', { headers: useRequestHeaders(['cookie']) })
      const parsed = superjsonParse<ExchangeOfficeEmployeeBranch[]>(result)

      exchangeOfficeEmployeeBranches.value = parsed
    }
    catch (e: any) {
      console.error(e)
      return toast.error(e.statusMessage || 'Unable to fetch branches')
    }
    finally {
      isExchangeOfficEmployeeeBranchesFetched.value = true
    }
  }

  const selectExchangeOfficeEmployeeBranch = (branchId: number) => {
    const branch = exchangeOfficeEmployeeBranches.value?.find(b => b.id === branchId)
    if (!branch)
      return toast.error('Unable to select a branch')

    selectedExchangeOfficeBranch.value = branch
    localStorage.setItem('selected-exchange-office-branch-id', branchId.toString())
  }

  const selectExchangeOfficeEmployeeCashDesk = async (cashDeskId: number) => {
    const cashDesk = selectedExchangeOfficeBranch.value?.ExchangeOfficeCashDesk.find(cd => cd.id === cashDeskId)
    if (!cashDesk)
      return toast.error('Unable to select a cash desk')

    await $fetch(`/api/exchange-office-employee/branch/cash-desk/${cashDeskId}`, {
      method: 'PATCH',
      body: {
        userId: user.value?.userId,
      },
    })
    selectedExchangeOfficeCashDesk.value = cashDesk
  }

  const checkKrakenBalance = async (fiatAmountEur: number) => {
    try {
      isKrakenBalanceFetched.value = false
      await fetchKrakenBalance()
    }
    catch (e: any) {
      toast.error(e.statusMessage || 'Error checking Kraken balance')
    }
    finally {
      isKrakenBalanceFetched.value = true
    }
    const eurBalance = krakenBalance.value?.balances.find(balance => balance.currencyCode === 'EUR')
    if (!eurBalance) {
      toast.error('Unable to get eur balance from Kraken')
      return
    }
    isKrakenBalanceEnough.value = eurBalance.balance >= fiatAmountEur ? true : false
  }

  const createExchangeOfficeClient = async (data: ExchangeOfficeClientDTO) => {
    try {
      const result = await $fetch('/api/exchange-office-employee/client', {
        method: 'POST',
        body: data,
      })
      const parsed = superjsonParse<ExchangeOfficeClient>(result)
      return parsed
    }
    catch (e: any) {
      toast.error(e.statusMessage || 'Unable to create a client')
      throw createError({
        statusCode: 422,
        statusMessage: 'Unable to create a client',
      })
    }
  }

  const fetchExchangeOfficeClientByEmail = async (email: string, force?: boolean) => {
    if (exchangeOfficeClients.value?.find(c => c.email === email) && !force)
      return

    const old = exchangeOfficeClients.value?.find(c => c.email === email)
    if (old)
      exchangeOfficeClients.value?.splice(exchangeOfficeClients.value.indexOf(old), 1)

    try {
      const result = await $fetch(`/api/exchange-office-employee/client/${email}`, { headers: useRequestHeaders(['cookie']) })
      const parsed = result ? superjsonParse<ExchangeOfficeClient>(result) : null

      if (parsed) {
        exchangeOfficeClients.value = [...(exchangeOfficeClients.value ?? []), parsed]
      }
    }
    catch (e: any) {
      return toast.error(e.statusMessage || 'Unable to fetch a client by email')
    }
    finally {
      isExchangeOfficeClientsFetched.value = true
    }
  }

  const fetchExchangeOfficeClientOrderSumLast21DaysByEmail = async (email: string) => {
    isClientOrderSumFetching.value = true
    try {
      const result = await $fetch(`/api/exchange-office-employee/client/${email}/sum`, { headers: useRequestHeaders(['cookie']) })
      const parsed = superjsonParse<SumClientResponse>(result)

      clientOrderSum.value = parsed === null ? null : parsed?.amount
      if (parsed?.clientPersonalNumber && parsed?.clientCountryCode) {
        clientOrderSumPersonalData.value = { clientPersonalNumber: parsed?.clientPersonalNumber, clientCountryCode: parsed?.clientCountryCode }
      }
      else {
        clientOrderSumPersonalData.value = undefined
      }
    }
    catch (e: any) {
      toast.error(e.statusMessage || 'Unable to get the client order sum')
    }
    finally {
      isClientOrderSumFetching.value = false
      isClientOrderSumFetched.value = true
      isClientOrderSumFetching.value = false
    }
  }

  const fetchExchangeOfficeClient = async (id: string) => {
    const old = exchangeOfficeClients.value?.find(c => c.id === id)
    if (old)
      exchangeOfficeClients.value?.splice(exchangeOfficeClients.value.indexOf(old), 1)

    isExchangeOfficeClientsFetched.value = false
    try {
      const result = await $fetch(`/api/exchange-office-employee/client/id/${id}`, { headers: useRequestHeaders(['cookie']) })
      const parsed = superjsonParse<ExchangeOfficeClient>(result)

      return exchangeOfficeClients.value = [...(exchangeOfficeClients.value ?? []), parsed]
    }
    catch (e: any) {
      console.error(e)
      toast.error('Unable to fetch a client')
    }
    finally {
      isExchangeOfficeClientsFetched.value = true
    }
  }

  const getExchangeOfficeClient = async (id: string) => {
    return exchangeOfficeClients.value?.find(c => c.id === id)
  }

  const getExchangeOfficeClientByEmail = (email: string) => {
    return exchangeOfficeClients.value?.find(c => c.email === email)
  }

  const generateOrderPin = async (orderId: number, transactionToAmount: number, transactionConversionRate: number) => {
    try {
      const result = await $fetch('/api/exchange-office-employee/generate-pin', {
        method: 'POST',
        body: { orderId, transactionToAmount, transactionConversionRate },
      })
      const parsed = superjsonParse<{ pin: string }>(result)
      if (!parsed.pin)
        throw createError({
          statusCode: 422,
          statusMessage: 'Failed to generate PIN',
        })

      return parsed
    }
    catch (e: any) {
      toast.error(e.statusMessage || 'Unable to generate PIN')
      throw createError({
        statusCode: 422,
        statusMessage: 'Failed to generate PIN',
      })
    }
  }

  const sendVerificationEmail = async (email: string) => {
    isSendEmailVerificationFetched.value = false
    try {
      const verificationCode = await $fetch(`/api/exchange-office-employee/client/${email}/send-verification-email`, {
        method: 'POST',
      })
      toast.success($t('profile.exchangeOffice.employees.verificationCodeSent'))
      return verificationCode
    }
    catch (e: any) {
      toast.error(e.statusMessage || 'Unable to generate PIN')
    }
    finally {
      isSendEmailVerificationFetched.value = true
    }
    return ''
  }

  const fetchExchangeOfficeClientVerificationStatus = async (exchangeOfficeClientId: string, type: VerificationType) => {
    const queryParams = new URLSearchParams()
    queryParams.append('exchangeOfficeClientId', exchangeOfficeClientId)
    queryParams.append('type', type)

    try {
      const result = await $fetch(`/api/exchange-office-employee/client/verification?${queryParams.toString()}`, { headers: useRequestHeaders(['cookie']) })
      const parsed = superjsonParse<ClientVerificationResult>(result)

      currentExchangeOfficeClientVerificationStatus.value = parsed
    }
    catch (e: any) {
      currentExchangeOfficeClientVerificationStatus.value = undefined
      toast.error(e.statusMessage || 'Unable to fetch client verification status')
    }
  }

  return {
    selectedExchangeOfficeBranch,
    selectedExchangeOfficeCashDesk,
    exchangeOfficeEmployeeBranches,
    isExchangeOfficEmployeeeBranchesFetched,
    isKrakenBalanceEnough,
    exchangeOfficeClients,
    isExchangeOfficeClientsFetched,
    clientOrderSum,
    clientOrderSumPersonalData,
    isClientOrderSumFetched,
    isClientOrderSumFetching,
    isCurrentSessionEmailVerified,
    isSendEmailVerificationFetched,
    currentExchangeOfficeClientVerificationStatus,
    isExchangeOfficeClientVerificationStarting,
    fetchExchangeOfficeEmployeeBranches,
    selectExchangeOfficeEmployeeBranch,
    selectExchangeOfficeEmployeeCashDesk,
    checkKrakenBalance,
    createExchangeOfficeClient,
    fetchExchangeOfficeClientByEmail,
    fetchExchangeOfficeClient,
    getExchangeOfficeClient,
    getExchangeOfficeClientByEmail,
    fetchExchangeOfficeClientOrderSumLast21DaysByEmail,
    generateOrderPin,
    sendVerificationEmail,
    fetchExchangeOfficeClientVerificationStatus,
  }
})
