import type { SwapCalculationInput, SwapCalculationOutput } from '@base/types'

// this pure function gets an object of all arguments necessary to calculate an exchange and returns an object of all calculated values
// it does not fetch any data from api, db or other sources
export function calculateSwap (input: SwapCalculationInput): SwapCalculationOutput {
// basic checks
  if (input.calculationDirection === 'from' && input.fromAmount === null)
    throw new Error('fromAmount is required when calculationDirection is "from"')

  if (input.calculationDirection === 'to' && input.toAmount === null)
    throw new Error('toAmount is required when calculationDirection is "to"')

  if (input.exchangeDirection === 'FIAT_TO_CRYPTO' && input.toChainId === null)
    throw new Error('toChainId is required when exchangeDirection is "FIAT_TO_CRYPTO"')

  if (!input.fromCurrency.priceEur)
    throw new Error('fromCurrency.priceEur must be set')

  if (!input.toCurrency.priceEur)
    throw new Error('toCurrency.priceEur must be set')

  // declare variables
  let fromAmount: number
  let fiatAmountEur: number
  let profitBitbeliEur: number
  let profitPlatformEur: number
  let chargeAmountEur: number
  let fromAmountEurAfterCharge: number
  let makerFeeEur: number
  let fromAmountEurAfterChargeWithMakerFee: number
  let toAmount: number
  let chainCharge: number
  let toAmountAfterCharge: number
  let minimumOrder: SwapCalculationOutput['minimumOrder']

  // calculations for both "from" and "to"
  const conversionRateEur: number = input.fromCurrency.type === 'FIAT' ? roundMath(1 / input.fromCurrency.priceEur, 8) : roundMath(1 / input.toCurrency.priceEur, 8)
  const chargePercent = input.profitBitbeliPercent + input.profitPlatformPercent
  const exchangePair = input.toCurrency.type === 'CRYPTO' ? input.toCurrency.pairEur : input.fromCurrency.pairEur

  // calculations for "from"
  if (input.calculationDirection === 'from') {
    fromAmount = input.fromAmount!
    fiatAmountEur = fromAmount * input.fromCurrency.priceEur
    profitBitbeliEur = fiatAmountEur * input.profitBitbeliPercent / 100
    profitPlatformEur = fiatAmountEur * input.profitPlatformPercent / 100
    chargeAmountEur = profitBitbeliEur + profitPlatformEur
    fromAmountEurAfterCharge = fiatAmountEur - chargeAmountEur
    makerFeeEur = fromAmountEurAfterCharge * input.makerFeePercent / 100
    fromAmountEurAfterChargeWithMakerFee = fromAmountEurAfterCharge + makerFeeEur
    toAmount = fromAmountEurAfterCharge / input.toCurrency.priceEur
    chainCharge = input.toCurrency.chainCharges.find(charge => charge.chainId === input.toChainId)?.charge || 0
    minimumOrder = input.toCurrency.chainCharges.find(charge => charge.chainId === input.toChainId)?.minimumOrder || null
    toAmountAfterCharge = toAmount - chainCharge
  }
  // calculations for "to"
  else {
    chainCharge = input.toCurrency.chainCharges.find(charge => charge.chainId === input.toChainId)?.charge || 0
    minimumOrder = input.toCurrency.chainCharges.find(charge => charge.chainId === input.toChainId)?.minimumOrder || null
    toAmount = input.toAmount!
    toAmountAfterCharge = toAmount - chainCharge
    fromAmountEurAfterCharge = toAmount * input.toCurrency.priceEur
    makerFeeEur = fromAmountEurAfterCharge * input.makerFeePercent / 100
    fromAmountEurAfterChargeWithMakerFee = fromAmountEurAfterCharge + makerFeeEur
    fiatAmountEur = fromAmountEurAfterCharge / (1 - (input.profitBitbeliPercent + input.profitPlatformPercent) / 100)
    profitBitbeliEur = fiatAmountEur * input.profitBitbeliPercent / 100
    profitPlatformEur = fiatAmountEur * input.profitPlatformPercent / 100
    chargeAmountEur = profitBitbeliEur + profitPlatformEur
    fromAmount = fiatAmountEur / input.fromCurrency.priceEur
  }

  const output: SwapCalculationOutput = {
    calculationDirection: input.calculationDirection,
    exchangeDirection: input.exchangeDirection,
    fromAmount: roundMath(fromAmount, input.fromCurrency.type === 'FIAT' ? 2 : 8),
    fromCurrencyCode: input.fromCurrency.code,
    fromChainId: input.fromChainId || null,
    conversionRateEur: roundMath(conversionRateEur, 8),
    fiatAmountEur: roundMath(fiatAmountEur, 2),
    profitBitbeliPercent: input.profitBitbeliPercent,
    profitBitbeliEur: roundMath(profitBitbeliEur, 2),
    profitPlatformPercent: input.profitPlatformPercent,
    profitPlatformEur: roundMath(profitPlatformEur, 2),
    chargePercent: chargePercent,
    chargeAmountEur: roundMath(chargeAmountEur, 2),
    fromAmountEurAfterCharge: roundMath(fromAmountEurAfterCharge, 2),
    makerFeePercent: input.makerFeePercent,
    makerFeeEur: roundMath(makerFeeEur, 2),
    fromAmountEurAfterChargeWithMakerFee: roundMath(fromAmountEurAfterChargeWithMakerFee, 2),
    toAmount: roundMath(toAmount, input.toCurrency.type === 'FIAT' ? 2 : 8),
    toCurrencyCode: input.toCurrency.code,
    toChainId: input.toChainId || null,
    chainCharge: chainCharge,
    toAmountAfterCharge: roundMath(toAmountAfterCharge, input.toCurrency.type === 'FIAT' ? 2 : 8),
    exchangePair: exchangePair,
    conversionRate: roundMath(fromAmount / toAmount, 8),
    minimumOrder: minimumOrder,
  }

  return output
}
