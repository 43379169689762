<script lang="ts" setup>
import type { CoinWithAmount } from '@base/types'

const props = defineProps<{
  name: string
  modelValue: CoinWithAmount
  showOnly?: 'fiat' | 'tokens'
  error?: string
  hideDropdown?: boolean
  readonly?: boolean
  suffix?: string
}>()

const emit = defineEmits(['update:modelValue'])
const modelValue = useVModel(props, 'modelValue', emit)

// interface Options {
//   preProcess: (val: string) => string
//   postProcess: (val: string) => string
// }

// // const fiatMaskaOptions: Options = {
// //   preProcess: (val) => {
// //     val = val.replace(/\s+/g, '')// only remove blanks
// //     return val
// //   },
// //   postProcess: (val) => {
// //     if (!val)
// //       return ''

// //     val = val.replace(/\s+/g, '').replace(/,/g, '.')

// //     const decimalLength = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)

// //     const processed = Intl.NumberFormat('cs-CZ', {
// //       style: 'decimal',
// //       useGrouping: true,
// //       minimumFractionDigits: 2,
// //       maximumFractionDigits: 2,
// //     }).format(Number(val))
// //       .slice(0, decimalLength ? -decimalLength : undefined)// removes decimal part if not needed

// //     return processed
// //   },
// // }

// // const cryptoMaskaOptions: Options = {
// //   preProcess: (val) => {
// //     val = val.replace(/\s+/g, '')// only remove blanks
// //     return val
// //   },
// //   postProcess: (val) => {
// //     if (!val)
// //       return ''

// //     val = val.replace(/\s+/g, '').replace(/,/g, '.')

// //     const decimalLength = 9 - (val.includes('.') ? val.length - val.indexOf('.') : 0)

// //     const processed = Intl.NumberFormat('cs-CZ', {
// //       style: 'decimal',
// //       useGrouping: false,
// //       minimumFractionDigits: 8,
// //       maximumFractionDigits: 8,
// //     }).format(Number(val))
// //       .slice(0, decimalLength ? -decimalLength : undefined)// removes decimal part if not needed

// //     return processed
// //   },
// // }

function amountChanged (event: Event) {
  if (
    !(
      [',', '.'].includes((event as InputEvent).data!)// , or . was the last character
      || (/,|\./.test((event.target as HTMLInputElement).value) && (event as InputEvent).data === '0') // or input contains , or . and 0 was the last character
    )
  )
    modelValue.value = { ...modelValue.value, amount: +(event.target as HTMLInputElement).value }
}
</script>

<template>
  <div>
    <div w-full relative mt-2>
      <!-- <input
        v-if="modelValue.type === 'FIAT'"
        v-maska:[fiatMaskaOptions]
        data-maska="0,99"
        data-maska-tokens="0:\d:multiple|9:\d:optional"
        :value="modelValue.amount" type="text" placeholder="0" w-full
        pl-24px
        pr-130px py-16px fubex-rounded-lg border-1
        border-neutral-200 focus:fubex-focus-ring
        duration-200
        dark="bg-neutral-200 border-neutral-600"
        @input="amountChanged"
      >
      <input
        v-else
        v-maska:[cryptoMaskaOptions]
        data-maska="0,99999999"
        data-maska-tokens="0:\d:multiple|9:\d:optional"
        :value="modelValue.amount" type="text" placeholder="0" w-full
        pl-24px
        pr-130px py-16px fubex-rounded-lg border-1
        border-neutral-200 focus:fubex-focus-ring
        duration-200
        dark="bg-neutral-200 border-neutral-600"
        @input="amountChanged"
      > -->
      <input
        v-if="modelValue.type === 'FIAT'"
        :value="modelValue.amount" type="number" placeholder="0" w-full
        pl-24px
        pr-130px py-16px fubex-rounded-lg border-1
        border-neutral-200 focus:fubex-focus-ring
        duration-200
        dark="bg-neutral-200 border-neutral-600"
        :readonly="props.readonly"
        @input="amountChanged"
      >
      <input
        v-else
        :value="modelValue.amount" type="number" placeholder="0" w-full
        pl-24px
        pr-130px py-16px fubex-rounded-lg border-1
        border-neutral-200 focus:fubex-focus-ring
        duration-200
        dark="bg-neutral-200 border-neutral-600"
        :readonly="props.readonly"
        @input="amountChanged"
      >
      <div
        v-if="!hideDropdown"
        absolute right-0 top-0 w-130px h-full text-shades-0
      >
        <SwapCurrencyDropdown
          v-model="modelValue"
          :show-only="props.showOnly"
        />
      </div>
      <div
        v-if="props.suffix"
        absolute right-0 top-0 h-full flex flex-col justify-center items-end pr-12px
      >
        {{ props.suffix }}
      </div>
    </div>
    <div v-if="error" text-error-default paragraph-md mt-8px>
      {{ error.includes('__key__') ? $t(error.replace('__key__', '')) : error }}
    </div>
  </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
